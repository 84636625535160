import Image from "next/image"
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { faAngleRight as falAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import demeShipImg from '../../public/assets/images/deme_ship.jpg'
import lamborghiniImg from '../../public/assets/images/news_lamborghini.jpg'
import cyberTruckImg from '../../public/assets/images/cybertruck_thumb.jpg'

const FontAwesomeIcon = dynamic(() =>
  import('@fortawesome/react-fontawesome').then((fa) => fa.FontAwesomeIcon)
)

export default function Cases() {
  return (
    <section className="cases-home py-4 py-lg-5">
      <div className="container">
        <h2 className="title pb-5">
          <small>Cases</small> Aerodynamics are everywhere
        </h2>
        <div className="card">
          <div className="row g-0">
            <div
              className="col-auto mr-5 d-none d-lg-block"
              style={{ marginBottom: '-6px' }}
            >
              <Image
                src={demeShipImg}
                width="280"
                height="325"
                className="cases-landing"
                alt="Deme Ship" />
            </div>
            <div className="col">
              <div className="card-body mr-xl-5">
                <h3 className="card-title py-xl-3">
                  DEME's "Innovation" Vessel
                </h3>
                <p className="card-text">
                  There are clearly quite a few good reasons to focus on
                  renewable wind energy. And with most of the accessible windy
                  spots on land already spoken for, the vast open area of the
                  seas are today's big opportunity - offshore wind energy. With
                  strong & predictable winds, undisturbed by any trees or
                  buildings nearby, it's a dream for anyone building a business
                  case.
                </p>
                <Link
                  passHref
                  href="/cases/deme-increasing-workability-of-offshore-operations"
                  className="btn-link d-block float-end text-primary">
                  Read More <FontAwesomeIcon icon={falAngleRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card my-5">
          <div className="row g-0">
            <div
              className="col-auto mr-5 d-none d-lg-block"
              style={{ marginBottom: '-6px' }}
            >
              <Image
                src={lamborghiniImg}
                width="280"
                height="320"
                className="cases-landing"
                alt="Lamborghini Gallardo Aerodynamics" />
            </div>
            <div className="col">
              <div className="card-body mr-xl-5">
                <h3 className="card-title py-xl-3">
                  Lamborghini Gallardo Aerodynamics
                </h3>
                <p className="card-text">
                  Lamborghini's are fast, really fast. There are those who are
                  happy with a base Lamborghini Gallardo. And then there are
                  those who make them even faster. In the highly competitive
                  scene of 1-mile drag racing, an undisclosed customer turned to
                  state-of-the-art technology to gain the edge.
                </p>
                <Link
                  passHref
                  href="/cases/lamborghini-gallardo-drag-race-aerodynamics"
                  className="btn-link d-block float-end text-primary">
                  Read More <FontAwesomeIcon icon={falAngleRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card my-5">
          <div className="row g-0">
            <div
              className="col-auto mr-5 d-none d-lg-block"
              style={{ marginBottom: '-6px' }}
            >
              <Image
                src={cyberTruckImg}
                width="280"
                height="320"
                className="cases-landing"
                alt="Tesla Cybertruck"
                style={{
                  maxWidth: "100%",
                  height: "auto"
                }} />
            </div>
            <div className="col">
              <div className="card-body mr-xl-5">
                <h3 className="card-title py-xl-3">
                  Tesla Cybertruck Aerodynamics
                </h3>
                <p>
                  The breaking glass during the unveiling of the Tesla
                  Cybertruck wasn't the only shock: the exterior design
                  completely breaks with the hyper-streamlined styling of the
                  other Tesla models. As aerodynamics is the dominant factor for
                  the range of electric vehicles, we wanted to find out what was
                  playing.
                </p>
                <Link
                  passHref
                  href="/cases/tesla-cybertruck-aerodynamics"
                  className="btn-link d-block float-end text-primary">
                  Read More <FontAwesomeIcon icon={falAngleRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-5">
          <Link passHref href="/cases" className="btn btn-lg btn-primary">
            See More Cases <FontAwesomeIcon icon={falAngleRight} />
          </Link>
        </div>
      </div>
    </section>
  );
}
